<template>
    <v-footer dark padless>
        <v-container>
            <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                    <p class="text-overline">Corporate</p>
                    <p class="text-caption">Godolphin Exploration Ltd is registered in England. Registration number: 
                        <u><a href="https://find-and-update.company-information.service.gov.uk/company/13288823" target="_blank" style="color: inherit; text-decoration: inherit">13288823</a></u>
                    </p>
                </v-col>
                <v-col align="center" justify="center">
                    <p class="text-overline">Contact</p>
                    <p><ContactButtons></ContactButtons></p>
                </v-col>
                <v-col align="center" justify="center">
                    <p class="text-overline">Registered Address</p>
                    <p class="text-caption">c/o 6th Floor 60 Gracechurch Street, London, United Kingdom, EC3V 0HR
                    </p>
                </v-col>
            </v-row>
        </v-container>
      <v-card
        xxflat
        xxtile
        width="100%"
        class="text-center"
      >
        <!-- 
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider> -->

        <v-card-text class="white--text">
          &copy; {{ new Date().getFullYear() }} — <strong>Godolphin Exploration</strong>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
import ContactButtons from './ContactButtons.vue'
export default {
    components: {
        ContactButtons,
    },
    data: () => ({
      icons: [
        'mdi-home',
        'mdi-email',
        'mdi-calendar',
        'mdi-delete',
      ],
      items: [
        'default',
        'absolute',
        'fixed',
      ],
      padless: false,
      variant: 'default',
    }),
    computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },
    },
}
</script>