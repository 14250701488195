<template>

	<div class="projects-box">
		&nbsp;
		<h2 class="h2 text-center">RUSS <span class="g-accent">MODEL</span></h2>

		<v-container>
			<v-row>
				<v-col class="col-12 col-sm-7 col-md-6">
					<p class="text">Developed by Dr Keith Russ, the 3D model represents the results of more than 20yrs of acquiring and digitising mine workings.</p>
					<p>It constitutes accurate location and underground development of 500+ mines.</p>				
					<p>Combining the model with our IP allows us to identify opportunities without large capital outlay.</p>
					<p>Drill-ready targets: Accurate exploration drill targeting of remaining mineralised material in DGC and DoL mines.</p>

				</v-col>
				<v-col class="col-12 col-sm-5 col-md-6">
					<v-img src="/images/projects/dgc.png"></v-img>
					<p class="text-center">North east view of the Main and South lodes, Devon Great Consols</p>
				</v-col>
			</v-row>
		</v-container>


		<Banner text="Advanced&nbsp;<u>3d data analysis</u>"></Banner>
	</div>
</template>

<script>
// import {mapState} from 'vuex'
import Banner from './Banner.vue'
export default {
	name: 'RussModel',
	components: {
		Banner
	},
	computed: {
		// ...mapState({
			// team: state => state.team
		// })
	},
	data() {
		return {
		}        
	},
}
</script>

<style scoped>
.my-list li {
	/* list-style-type: none; */
	/* color:#ED7D31; */
	/* padding: 10px; */
	/* margin: 10px; */
	/* text-indent: 10px; */
}

/* li {
	list-style-position: inside;
	padding-left: 1em;
} */

/* li::before {
	content: "+";
	color:#ED7D31;
	font-weight: bold;
	padding-right: 10px;
    /* content: "\e080"; */
    /* font-family: 'Glyphicons Halflings'; */
    /* font-size: 9px; */
    /* position: relative; */
    /* margin-left: 10px; */
	/* padding-left: 10px; */
    /* top: 3px; */
    /* color: #ccc; */
/*} */
.projects-box {
	width: 100%;
	/* margin: 10px; */
	margin-top: 30px;
	padding: 10px;
	/* background-color: lightgray; */
	border: lightgrey 15px solid;
}
.g-accent {
	color: #ED7D31;
	/* background: none; */
}

/* .team-image {
	width: 56px;
	height: 56px;
} */
</style>>

