<template>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      xxtemporary
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
    >
      <v-toolbar flat dark>
        Menu
      </v-toolbar>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-blue--text text--accent-4"
        >

          <v-list-item v-for="item in menu" v-bind:key="item.id" @click="navTo(item.route)">
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item>

          <!--
          <v-list-item @click="navTo('/')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="navTo('/corporate')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
          -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState({
      '_drawer': state => state.drawer,
      menu: state => state.menu
    }),
    drawer: {
      get() { return this._drawer },
      set(v) { this.$store.dispatch("toggleDrawer", v); return v },
    },
  },
  data() {
    return {
      group: null,
    }
  },
  methods: {
    navTo(route) {
      // this.$router.push(route)
      this.$emit('navTo', route)
      this.$store.dispatch("toggleDrawer")
    },
  },
}
</script>
