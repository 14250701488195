<template>
    <v-carousel cycle class="carousel"
        :height="height">
        <v-carousel-item v-for="image in images" v-bind:key="image.id"
            transition="scroll-x-transition"
            reverse-transition="scroll-x-transition">
            <v-parallax id="scrolling" :src="image.src">
            <!-- <v-img :src="image.src"> -->
                <v-row>
                    <v-col style="text-color: white; padding-left: 20px; padding-top: 25px">
                        <p class="text-h5 text-sm-h4 text-md-h3">
                            Metals for the Green Energy Transition
                        </p>
                    </v-col>
                </v-row>
            <!-- </v-img> -->
            </v-parallax>
        </v-carousel-item>
    </v-carousel>
</template>

<!--
    <v-parallax id="scrolling" src="/images/green-energy.png">
      <v-row align="top" justify="left">
        <v-col>
          <h1 class="heading2">
            Metals for the Green Energy Transition
          </h1>
        </v-col>
      </v-row>
    </v-parallax>
-->

<script>
export default {
    data() {
        return {
            images: [
                {id:2, src:"/images/green-energy.png"},
                {id:0, src:'https://wallpaperaccess.com/full/2183826.jpg'},
                {id:1, src:'https://wallpaperaccess.com/full/1893408.jpg'},
                {id:3, src:"https://media.istockphoto.com/photos/image-of-the-renewable-energy-picture-id586163548?k=6&m=586163548&s=612x612&w=0&h=uVusrpwtcNHdxeZH8F9-ziwys0HgjcgpUc6zCMTajKA="},
                // {id:4, src:"http://www.resweld.com/wp-content/uploads/2017/04/Fotolia_140090800_Subscription_XL-1024x683.jpg"},
                {id:5, src:"https://www.downloadclipart.net/large/copper-wire-png-transparent-picture.png"},
            ]
        }
    },
    computed: {
        height() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 300
                case 'sm': return 350
                case 'md': return 400
                case 'lg': return 450
                case 'xl': return 500
                default: return 300
            }
        },
    },
}
</script>

<style scoped>
.carousel {
    background-color: grey;
}
</style>