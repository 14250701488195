<template>
    <div>
        <v-btn text small href="https://www.linkedin.com/company/godolphin-exploration/" target="_blank">
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <v-btn text small href="mailto:hello@godolphinx.com">
          <v-icon>mdi-email</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'ContactButtons',
}
</script>