<template>

  <v-container fluid class="projects-box">
    &nbsp;
    <h2 class="h2 text-center">OUR <span class="g-accent">PROJECTS</span></h2>

    <v-card>
      <v-card-title>DEVON GREAT CONSOLS&nbsp;<span class="g-accent">A SHORT HISTORY</span></v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <ul>
                <li>Founded in 1844 </li>
                <li>Paid-up capital of £1,024 </li>
                <li>(1024 shares of £1 each)</li>
                <li>Copper discovered 60 ft from surface</li>
                <li>Main lode of 2 miles long, up to 45ft wide</li>
                <li>Produced 90,000 t of copper in first 6 years</li>
                <li>Mined down to 550m depth only</li>
                <li>Paid over £800,000 in dividends!</li>
                <li>Closed in 1903</li>
                <li>Tin zone beneath the copper long posited</li>
                <li>Only 1 deep hole ever drilled at DGC</li>
              </ul>
            </v-col>
            <v-col>
              <v-img src="/images/project/dgc.png"></v-img>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>

    </v-card>

    <v-card>
      <v-card-title>DUKE OF LEEDS&nbsp;<span class="g-accent">GREAT WHEAL VOR</span></v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <ul>
                <li>A tin and copper mine situated near to the village of Carleen, about two miles northwest of Helston. The mine incorporated other mines including Carleen Mine, also known as West Wheal Vor or Wheal Emma, Great Wheal Vor, Wheal Metal and Great Wheal Fortune within its sett. The mine was first worked by the Godolphin family during the 16th and 17th centuries. In 1710 it was the first mine in Cornwall to have a Newcomen engine installed. The mine was reopened in 1810 but was closed from 1848 to 1853 and reopened in 1858. The mine comprised extensive workings which included 15 or so engines and was described by the historian Joseph Y. Watson to 'resemble a small town'. The mine reached its peak between 1812 and 1848 when it mined 'the richest tin lode in all of Cornwall'.</li>
                <li>Production moved south to Wheal Metal. In 1874 all production stopped in the mines to the east of Edward's Shaft. In 1906 there was an unsuccessful attempt at reworking sections of Wheal Vor. Further attempts were also made as late as 1967, but these enterprises came to nothing.
                  <v-img src="/images/project/wood-tin.png"></v-img>
                  <p class="text-center">Wood Tin, Wheal Vor <br>Source: mindat.com</p>
                </li>
              </ul>
            </v-col>
            <v-col>
              <v-img src="/images/project/wheal-vor.jpg"></v-img>
              <p class="text-center">Wheal Vor Creases Shaft, 1908 <br>Source: helstonhistory.co.uk</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>

    <Banner text="Multiple <u>drill-ready targets</u>"></Banner>
  </v-container>
</template>

<script>
// import {mapState} from 'vuex'
import Banner from './Banner.vue'
export default {
  name: 'Projects',
  components: {
    Banner
  },
  computed: {
    // ...mapState({
      // team: state => state.team
    // })
  },
  data() {
    return {
    }        
  },
}
</script>

<style scoped>
.projects-box {
  width: 100%;
  /* margin: 10px; */
  margin-top: 30px;
  padding: 10px;
  /* background-color: lightgray; */
  border: lightgrey 15px solid;
}
.g-accent {
  color: #ED7D31;
  /* background: none; */
}
.wide {
  min-height: 45px;
  background: #FFC000;
}
/* .team-image {
  width: 56px;
  height: 56px;
} */
</style>>

