<template>

  <div>
    <v-app-bar
        app
        dark
        color="secondary"
        elevate-on-scroll
        xxsrc="/images/wind-1.png"
        fade-img-on-scroll
        scroll-target="#scrolling"
      >
        <!-- used to customise the src img slot
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>
        -->

        <v-app-bar-nav-icon @click="toggleDrawer()" class="d-flex d-md-none"></v-app-bar-nav-icon>

        <v-app-bar-title style="padding-left: 5px; cursor: pointer">
          <div @click="scrollTo('scrolling')" class="nodecor">
            <table width="100%">
              <tr>
                <td>
                  <v-img width="24px" height="36px" :src="images.logo1"></v-img>
                </td>
                <td class="logo-font">
                  <span style="font-size: 18px">GODOLPHIN</span><br>
                  <span style="font-size: 15.5px">EXPLORATION</span>
                </td>
              </tr>
            </table>
          </div>
        </v-app-bar-title>

        <v-spacer></v-spacer>

        <v-btn class="d-none d-md-flex" @click="scrollTo('about')" outlined text>About</v-btn>
        <v-btn class="d-none d-md-flex" @click="scrollTo('team')" outlined text>Team</v-btn>
        <v-btn class="d-none d-md-flex" @click="scrollTo('russ-model')" outlined text>Russ Model</v-btn>
        <v-btn class="d-none d-md-flex" @click="scrollTo('projects')" outlined text>Projects</v-btn>
        <v-btn class="d-none d-md-flex" @click="scrollTo('corporate')" outlined text>Press/Media</v-btn>
        <!-- <v-btn class="d-none d-md-flex" to="/esg" outlined text>ESG</v-btn> -->
        <!-- <v-btn class="d-none d-md-flex" to="/investors" outlined text>Investors</v-btn> -->
        <!-- <v-btn class="d-none d-md-flex" to="/social" outlined text>Social</v-btn> -->
        <!-- <v-btn class="d-none d-md-flex" to="/contact" outlined text>Contact</v-btn> -->

        <v-spacer></v-spacer>

        <ContactButtons></ContactButtons>
      </v-app-bar>

    </div>

</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import ContactButtons from './ContactButtons.vue'
export default {
  name: "Appbar",
  components: {
    ContactButtons
  },
  data() {
    return {
      images: {
        logo1: require('../assets/logo-sm-white-on-clear.png'),
        logo2: require('../../public/logo-sm-black-on-clear.png'),
      },
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
    }
  },
  methods: {
    toggleDrawer() {
      //this.$emit('toggleDrawer')
      this.$store.dispatch('toggleDrawer', true)
    },
    scrollTo(refName) {
      console.debug("Appbar.vue: scrollTo()", refName)
      this.$emit('scrollTo', refName)
      // var element = this.$refs[refName];
      // var top = element.offsetTop;
      // window.scrollTo(0, top);
    }
  }
}
</script>

<style scoped>
.nodecor {
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
}
.logo-font {
  font-size: 16px;
}
</style>