import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Corporate from '../views/Corporate.vue'
import ESG from '../views/ESG.vue'
import About from '../components/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/corporate',
    name: 'Corporate',
    component: Corporate
  },
  {
    path: '/esg',
    name: 'ESG',
    component: ESG
  },
  {
    path: '/investors',
    name: 'Investors',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
