<template>

	<div class="team-box">
		&nbsp;
		<h2 class="h2 text-center">OUR <span class="g-accent">TEAM</span></h2>

		<v-container>
			<v-row>
				<v-col class="col-12 col-sm-6" v-for="item in team.filter(f => f.active)" v-bind:key="item.id">
					<TeamMember :item="item"></TeamMember>
				</v-col>
			</v-row>
		</v-container>

		<Banner text=">150 years’ <u>experience</u>"></Banner>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import TeamMember from './TeamMember.vue'
import Banner from './Banner.vue'
export default {
	name: 'Team',
	components: {
		TeamMember,
		Banner,
	},
	computed: {
		...mapState({
			team: state => state.team
		})
	},
	data() {
		return {
		}        
	},
}
</script>

<style scoped>
.team-box {
	width: 100%;
	/* margin: 10px; */
	margin-top: 30px;
	padding: 10px;
	/* background-color: lightgray; */
	border: rgb(157, 188, 207) 15px solid;
}
.g-accent {
	color: #ED7D31;
	/* background: none; */
}
.wide {
	min-height: 45px;
	background: #FFC000;
}
/* .team-image {
	width: 56px;
	height: 56px;
} */
</style>>

