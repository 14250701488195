<template>
    <v-card class="fact-box">
        <div style="float:right">
            <v-btn text small @click="cycle()">
                <v-icon small>mdi-refresh</v-icon>
            </v-btn>
        </div>
        <h2 class="h2 text-center">FUN <span class="g-accent">FACT</span>
        </h2>
        <!-- <v-card-title>{{fact.title}}</v-card-title> -->
        <v-card-text>
            <p class="text-center text-h6" v-html="fact.title">
                {{index+1}}. {{fact.title}}
            </p>
            <p class="text-center" v-html="fact.text">
                <!-- {{fact.text}} -->
                <!-- <span class="text-caption" v-show="fact.ref"><a :href="fact.ref" target="_blank">- ref.</a></span> -->
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'FunFact',
    computed: {
        fact() {
        //     let len = this.facts.length
        //     this.fact = this.facts[Math.floor(Math.random()*len)];
        //     return this.fact
            return this.facts[this.index]
        }
    },
    data() {
        return {
            index: 0,
            // fact: {},
            facts: [
                {
                    title: "PVC degrades in UV light.", 
                    text: "uPVC is UV-stabilised by adding tin...! Tin (Sn) stabiliser is the best heat stabiliser, environmentally friendly and requires less dosage than Lead (Pb)", 
                    ref: null
                },
                {
                    title: "Tin boosts PV efficiency",
                    text: "A discovery by scientists at University of Groningen, Netherlands has shown that tin-based perovskite solar energy materials could more than double solar PV efficiency."
                        + '<a href="https://www.internationaltin.org/tin-could-more-than-double-solar-pv-efficiency/" target="_blank"><i>(ref)</i></a>',
                    ref: "https://www.internationaltin.org/tin-could-more-than-double-solar-pv-efficiency/"
                },
                {
                    title: "Pure copper's melting point is 1,981°F (1,083°C, 1356°K)",
                    text: 'Its most important properties include superior heat transfer, electrical conductivity and corrosion resistance.'
                },
                {
                    title: "Bronze<super>*</super> is harder than pure iron",
                    text: "... and far more resistant to corrosion. Bronze is also harder than pure copper, so the Egyptians used it for weapons, armor, tools and, most famously, sculptures. It is particularly well suited for sculpture because it expands when heated (filling the nooks and crannies of a mold), then contracts as it cools so the sculpture is easy to remove from the mold."
                    +"<br><i><super>*</super> Bronze is an alloy consisting primarily of copper, commonly with about 12-12.5% tin</i>"
                }
                // {title: "one", text: "lorum ipsum one"},
                // {title: "two", text: "lorum ipsum one"},
                // {title: "three", text: "lorum ipsum one"},
            ]
        }
    },
    methods: {
        reload() {
            //let len = this.facts.length
            this.index = Math.floor(Math.random()*this.facts.length)
        },
        cycle() {
            this.index = (this.index == this.facts.length-1) ? 0 : this.index + 1
        },
    },
    mounted() {
        this.reload()
    }
}
</script>

<style scoped>
.fact-box {
	width: 100%;
	/* margin: 10px; */
	margin-top: 30px;
	padding: 10px;
	/* background-color: lightgray; */
	border: #ED7D31 5px solid;
}
.g-accent {
	color: #ED7D31;
	/* background: none; */
}
</style>
