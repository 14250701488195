<template>
  <v-app class="smooth-scroll">
    <NavDrawer v-on:navTo="scrollTo"></NavDrawer>
    <Appbar v-on:scrollTo="scrollTo"></Appbar>
    <v-main>
      <!-- <transition name="fade"> -->
        <router-view/>
      <!-- </transition> -->
    </v-main>
    <!-- <v-sheet height="600px"></v-sheet> -->
    <!-- <NavBottom class="d-flex d-md-none"></NavBottom> -->
    <Footer></Footer>
  </v-app>
</template>

<script>
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import Appbar from '@/components/Appbar.vue'
import NavDrawer from '@/components/NavDrawer.vue'
// import NavBottom from '@/components/NavBottom.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Appbar,
    NavDrawer,
    // NavBottom,
    Footer,
  },
  computed: {},
  data: () => ({
    easing: 'easeInOutCubic',
    easings: Object.keys(easings),
  }),
  methods: {
    scrollTo(refName) {
      console.debug("App.vue: scrollTo()", refName)
      // // var element = this.$refs[refName];
      var element = document.getElementById(refName)
      // console.debug(element)
      // var top = element.offsetTop;
      // window.scrollTo(0, top);
      var options = {
        duration: 750, 
        offset: 0, 
        easing: this.easing
      }
      var target = element
      this.$vuetify.goTo(target, options)
    }
  },
  mounted() {
    window.addEventListener("contextmenu", e => e.preventDefault());
  }
};
</script>

<style>
.wide {
	min-height: 45px;
	background: #FFC000;
}
.smooth-scroll {
  scroll-behavior: smooth;
}
.slide-fade-enter-active {
   transition: all .3s ease;
}
.slide-fade-leave-active {
   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
   transform: translateX(10px);
   opacity: 0;
}
</style>