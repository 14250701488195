import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    menu: [
      // { id: 'home',   name: 'Home', route: '/', icon: 'mdi-home' },
      { id: 'home', name: 'Home', route: 'scrolling', icon: 'mdi-home' },
      { id: 'team', name: 'Team', route: 'team', icon: 'mdi-contacts' },
      { id: 'russ-model', name: 'Russ Model', route: 'russ-model', icon: 'mdi-map' },
      { id: 'projects', name: 'Projects', route: 'projects', icon: 'mdi-pickaxe' },
      { id: 'corporate', name: 'Press/Media', route: 'corporate', icon: 'mdi-file' },
      // { id: 'esg',    name: 'ESG', route: '/esg', icon: 'mdi-nature-people' },
      // { id: 'invest', name: 'Investors', route: '/investors', icon: 'mdi-chart-areaspline' },
      // { id: 'contact', name: 'Contact', route: '/contact', icon: 'mdi-card-account-mail-outline' },
    ],
    team: [
      {
        id: 'mark', active: true, name: "Mark Thompson", quals: 'B.A. (Oxon), Physics', title: "Commercial Development Director", 
        bio: 'Acknowledged metals market expert, expert witness, consultant. >25 years resources sector trading management, and investment experience. Former head of metals trading at Trafigura. Currently Executive Vice Chairman of Tungsten West, and Non-Executive Director of Meridian Mining.',
        image: '/images/team/mark.png',
        twitter: 'METhompson72', linkedin: 'mark-thompson-368136a' },
      {
        id: 'alan', active: true, name: "Alan Stephens", quals: false, 
        title: "CEO", 
        bio: "Alan Stephens is a Non-Executive Director of Marimaca Copper. He co-founded the Company in 2005 and transitioned from his executive to non-executive role in 2018. Alan is a respected exploration geologist, known for his involvement in the discovery of some of the world’s most significant copper mining operations. It is this geological experience and knowledge that Alan brings to the Board. Alan has served as the Vice President of Exploration for First Quantum and Exploration Manager for Cyprus Amax, managing exploration teams in Latin America, Africa, Europe and Asia. Alan is a Fellow of the Society of Economic Geologists and of the Institute of Materials, Minerals and Mining. He holds a Bachelor’s in Mining Geology from the Royal School of Mines, Imperial College, University of London. Alan is a British and US national, resident in the UK, and fluent in Spanish.",
        image: '/images/team/alan.png' ,
        twitter: null, linkedin: 'alan-stephens-bb97321bb' },
      {
        id: 'derek', active: true, name: "Derek Colley",
        quals: 'CA(SA), B.Com(ACC)',
        title: "Chief Financial Officer", 
        bio: "Derek is a qualified Chartered Accountant CA(SA) and has 25+ years experience in Finance and IT across a variety of sectors including mining, retail, logistics, construction, leisure and technology.<br>He was Head of Finance and IT at Tungsten West from 2020 to 2021 prior to joining Godolphin Exploration.", 
        image: '/images/team/derek.png',
        twitter: '_derekc_', linkedin: 'derekcolley' },
      {
        id: 'andrew', active: true, name: "Andrew Dacey", quals: '', 
        title: "Exploration Manager", 
        bio: "Andrew is a Registered Professional Geologist and member of the Chartered Institute of Securities & Investments. Prior to founding consultancy Momentum Group, he spent four years as the mining specialist for investment banking teams at Evolution Securities and Haywood Securities.  In 2019 he founded Element-46 Ltd, a PGM focused explorer which was sold in 2021.  In 2005 he co-founded Lydian International Ltd now developing the 5Moz Au Amulsar project and in 2001 he discovered the 3Moz Aueq Golden Hills project in Mongolia. He graduated with a degree in Geology from Victoria University of Wellington, NZ and completed a Masters of Economic Geology at CODES, Australia.  He is a competent person as defined by both the JORC and NI 43-101 codes.",
        image: '/images/team/andrew.png',
        twitter: null, linkedin: 'awdacey' },
      {
        id: 'steve', active: true, name: "Steve Reece", quals: null, title: "Non-executive Director", 
        bio: 'Former COO at Windrose Investment Management and Managind Directory at Standard Bank PLC. 25 year veteran of the Base Metals markets with a wealth of risk management and business development experience',
        image: '/images/team/steve2.png',
        twitter: null, linkedin: 'steven-reece-b2a18516a' },
      {
        id: 'vaughan', active: true, name: "Vaughan Wickens", quals: 'CA(SA). BSc (Finance), UCT', 
        title: "Non-executive Director", 
        bio: "Vaughan has extensive experience in advising and arranging mining finance transactions.<br>" 
          + "He spent three years with Freepoint Metals & Concentrates supporting their physical metals business. Prior to that he was with Standard Bank in London initially as Head of the Mining Finance team and later as an executive in their Mining and Metals Coverage team supporting clients across finance, physical trading, advisory, ECM and treasury. Over a 10 year period at the bank transactions included the advising and financing of Equinox's Lumwana Copper Project, various financings for First Quantum Minerals, African Barrick Gold, Anatolia Minerals, Discovery Metals, Talvivaara, European Nickel, Archipelago and Aim Resources.<br>"
          //+ "Vaughan is a CA(SA) registered with the South African Institute of Chartered Accountants and holds a Bachelor of Business Science degree from the University of Cape Town."
          ,  
        image: '/images/team/vaughan.png', 
        twitter: null, linkedin: 'vaughan-wickins-34a4524' },
      {
        id: 'charlie', active: true, name: "Dr. Charlie Moon", quals: 'BSc and ARSM, Mining Geology, PhD and DIC, Applied Geochemistry', 
        title: "Technical Advisor", 
        bio: 'Charlie has a BSc in Mining Geology and PhD in applied geochemistry from Imperial College, London and is a Fellow of the Geological Societies of London and South Africa. <br>He has 50 years experience of mineral deposit geology, exploration and discovery on 6 continents for junior and major companies, academia and a geological survey for a variety of commodities. His particular expertise is in the geology and mineral deposits of SW England as well as their mineral rights; he is a resident of Cornwall.',
        image: '/images/team/charlie.png',
        twitter: null, linkedin: 'charlie-moon-486aa548' },
      {
        id: 'keith', active: true, name: "Dr. Keith Russ", 
        quals: 'B.Eng (Hons) & PhD - Mining Engineering, ACSM', title: "Mining Advisor", 
        bio: "I am twice graduate of the Camborne School of Mines, (1990, 1994). Also a former lecturer in Surveying and computer packages at CSM. Since 2001 I have been involved in a full-time capacity with the South Crofty Project. Since 2020 I have been a full-time mining consult working with a number of companies and projects in Cornwall and elsewhere.<br>"
        + "In 1989 I created my first 3d computer model of mine workings as part of my degree final year project. Since then I have constructed over 500 mine models (340+ Cornwall and West Devon), on three continents. The modelling of mines and mining districts world wide continues.<br>"
        + "Since the early 2000's I have been a member of the council for the Cornish Institute of Engineers and have also served as President. I am currently an active volunteer for the King Edward Mining Museum, Camborne and hold the post of Health and Safety Officer.",
        image: '/images/team/keith.png',
        twitter: null, linkedin: 'keith-russ-4607713a'
      }, {
        active: false,
        id: 'josh', name:"Josh Chambers", quals: "BSc (Hons), Applied Geology", title: 'Geologist', 
        bio: 'Josh holds a BSc in Applied Geology from the Camborne School of Mines, University of Exeter. A graduate geologist with 3 years of experience immersed in the geology of south-west UK, learning from those fundamentally involved in past and current exploration and mining within the region.',
        image: '/images/team/josh.png',
        twitter: null, linkedin: 'jw-chambers' },
    ],
  },
  mutations: {
    TOGGLE_DRAWER(state) {
      state.drawer = !state.drawer
    },
  },
  actions: {
    toggleDrawer({commit, state}, payload) {
      // console.debug("store/index.js: toggleDrawer()...")
      if(state.drawer != payload)
        commit("TOGGLE_DRAWER")
    },
  },
  modules: {
  }
})
