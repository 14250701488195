<template>
  <v-container>

    &nbsp;
    <!-- HERO section -->
    <Hero></Hero>

    <About id="about" class="even"></About>
    <Team id="team" class="odd."></Team>
    <FunFact class="even"></FunFact>
    <RussModel id="russ-model" class="even"></RussModel>
    <Projects id="projects" class="odd."></Projects>
    <br>
    <Corporate id="corporate" class="even"></Corporate>

    <!-- <hello-world /> -->
  </v-container>
</template>

<script>
  import Hero from '../components/Hero.vue'
  import About from '../components/About.vue'
  import Team from '../components/Team.vue'
  import Projects from '../components/Projects.vue'
  import RussModel from '../components/RussModel.vue'
  import FunFact from '../components/FunFact.vue'
  import Corporate from '../components/Corporate.vue'

  export default {
    name: 'Home',

    components: {
      Hero,
      About,
      Corporate,
      Team,
      Projects,
      RussModel,
      FunFact,
    },
  }
</script>

<style scoped>
.even {
  background-color: white;
}
.odd {
  background-color: grey;
  /* padding: 20px; */
  /* margin: 20px; */
}
</style>
