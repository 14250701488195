<template>

    <v-container outlined fill-height class="team-member">

        <v-row>
          <v-col class="col-4 col-sm-5 col-md-4 col-lg-3">
            <v-list-item-avatar
              xtile
              :size="width"
              color="grey">
              <v-img :width="width" class="team-image" :src="item.image"></v-img>
            </v-list-item-avatar>
          </v-col>
          <v-col class="col-8 col-sm-7 col-md-6 col-lg-9">
            <v-list-item-title class="text-h5 mb-1">
              {{item.name}}
            </v-list-item-title>
            <div class="mb-4" v-show="item.quals">
              {{item.quals}}
            </div>
            <v-list-item-subtitle>{{item.title}}</v-list-item-subtitle>
          </v-col>
        </v-row>

        <v-row align="start" no-gutters>

          <v-col align="end" class="col-12">
            <v-btn icon text class="icon link" :href="'https://linkedin.com/in/'+item.linkedin" v-show="item.linkedin" target="_blank">
              <v-icon>mdi-linkedin</v-icon></v-btn>
            <v-btn icon fab small class="link" :href="'https://twitter.com/'+item.twitter" v-show="item.twitter" target="_blank">
              <v-icon v-show="item.twitter">mdi-twitter</v-icon></v-btn>
              <v-btn icon text class="icon link" @click="onDialog()"><v-icon>mdi-information-outline</v-icon></v-btn>
          </v-col>

          <!-- <v-col class="col-12 col-sm-9 col-md-10">
            <span class="d-flex d-sm-none">
              <v-btn icon text class="icon link" :href="'https://linkedin.com/in/'+item.linkedin" v-show="item.linkedin" target="_blank">
                <v-icon>mdi-linkedin</v-icon></v-btn>
              <v-btn icon fab small class="link" :href="'https://twitter.com/'+item.twitter" v-show="item.twitter" target="_blank">
                <v-icon v-show="item.twitter">mdi-twitter</v-icon></v-btn>
                <v-btn icon text class="icon link" @click="onDialog()"><v-icon>mdi-information-outline</v-icon></v-btn>
            </span>
          </v-col> -->

        </v-row>

    </v-container>

</template>

<script>
// import {mapState} from 'vuex'
export default {
  name: 'Team',
  props: ['item','dialog'],
  computed: {
      width () {
        //   console.debug('this.$vuetify.breakpoint.name', this.$vuetify.breakpoint.name)
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 80
          case 'sm': return 100
          case 'md': return 120
          case 'lg': return 130
          case 'xl': return 150
          default: return 80
        }
      },
  },
  data() {
    return {
    }        
  },
  methods: {
      onDialog() {
          this.$emit('onDialog', true)
      },
  }
}
</script>

<style scoped>
.team-member {
  border: lightgrey 1px solid;
  min-height: 180px;
}
.team-image {
  max-width: 200px;
  /* height: 100%; */
  aspect-ratio: 50 / 50;
  filter: grayscale(100%);
}
.link {
  text-decoration: none;
}
</style>
