<template>
    <v-container>
        <p class="title">ESG</p>
    </v-container>
</template>

<script>

export default {
    name: 'Corporate',
    data() {
        return {}
    },
}
</script>
