<template>

    <div class="team-member">

        <TeamMemberCard :item="item" v-on:onDialog="onDialog"></TeamMemberCard>
        <!-- <v-btn icon text class="icon link" @click="dialog=true"><v-icon>mdi-help</v-icon></v-btn> -->
        <!-- <v-row>
          <v-col class="col-4 col-sm-4 col-md-3">
            <v-list-item-avatar
              tile
              size="80"
              color="grey">
              <v-img class="team-image" :src="item.image"></v-img>
            </v-list-item-avatar>
          </v-col>
          <v-col class="col-8 col-sm-8 col-md-9">
            <v-list-item-title class="text-h5 mb-1">
              {{item.name}}
            </v-list-item-title>
            <div class="mb-4" v-show="item.quals">
              {{item.quals}}
            </div>
            <v-list-item-subtitle>{{item.title}}</v-list-item-subtitle>
          </v-col>
        </v-row> -->

        <!-- <v-row align="start" no-gutters>

          <v-col align="start" class="col-4 col-sm-3 col-md-2 d-none d-sm-flex">
            <v-btn icon text class="icon link" :href="'https://linkedin.com/in/'+item.linkedin" v-show="item.linkedin" target="_blank">
              <v-icon>mdi-linkedin</v-icon></v-btn>
            <v-btn icon fab small class="link" :href="'https://twitter.com/'+item.twitter" v-show="item.twitter" target="_blank">
              <v-icon v-show="item.twitter">mdi-twitter</v-icon></v-btn>
          </v-col>

          <v-col class="col-12 col-sm-9 col-md-10">
            <v-btn icon text class="icon link" @click="dialog=true"><v-icon>mdi-help</v-icon></v-btn>
            <span class="d-flex d-sm-none">
              <v-btn icon text class="icon link" :href="'https://linkedin.com/in/'+item.linkedin" v-show="item.linkedin" target="_blank">
                <v-icon>mdi-linkedin</v-icon></v-btn>
              <v-btn icon fab small class="link" :href="'https://twitter.com/'+item.twitter" v-show="item.twitter" target="_blank">
                <v-icon v-show="item.twitter">mdi-twitter</v-icon></v-btn>
            </span>
          </v-col>

        </v-row> -->

        <v-dialog
          v-model="dialog"
          max-width="600px"
        >
          <v-card>
            <v-card-title dark color="grey">{{item.name}}</v-card-title>
           
            <v-card-text>
              <!-- <TeamMemberCard :item="item"></TeamMemberCard> -->
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img class="team-image" :src="item.image"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.quals" v-show="item.quals"></v-list-item-subtitle>
                  
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-html="item.bio">
                </v-list-item>
              </v-list>
              
            </v-card-text>
           
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <!-- <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Save
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>

    </div>
  <!-- </v-card> -->
</template>

<script>
// import {mapState} from 'vuex'
import TeamMemberCard from './TeamMemberCard.vue'
export default {
  name: 'Team',
  props: ['item'],
  components: {
    TeamMemberCard,
  },
  computed: {
    // ...mapState({
    // 	team: state => state.team
    // })
  },
  data() {
    return {
      dialog: false
    }        
  },
  methods: {
    onDialog() {
      this.dialog=true
    },
  },
}
</script>

<style scoped>
.team-member {
  border: lightgrey 1px solid;
  background-color: white;
}
.team-image {
  max-width: 200px;
  /* height: 100%; */
  aspect-ratio: 50 / 50;
  filter: grayscale(100%);
}
.link {
  text-decoration: none;
}
</style>>

