<template>
    <v-container class="wide">
        <span class="text-h6 text-sm-h5 text-uppercase" v-html="text"></span>
    </v-container>
</template>

<script>
export default {
    props: ['text'],
}
</script>