<template>

	<v-container ref="About" class="about-box">
		&nbsp;
		<h2 class="h2 text-center">ABOUT <span class="g-accent">GODOLPHIN EXPLORATION</span></h2>

		<v-container>
			<v-row>

				<v-col class="col-12 col-sm-6" v-for="item in items" v-bind:key="item.id">
					<v-row align="center" class="about-item" :style="getStyle">
						<v-col class="col-4">
							<v-img :width="width" class="about-icon" :src="item.image"></v-img>
						</v-col>
						<v-col class="col-8">
							<p class="subtitle h4" v-html="item.html"></p>
						</v-col>
					</v-row>
				</v-col>

			</v-row>
		</v-container>

		<Banner text="COPPER AND TIN <u>EXPERTS</u>"></Banner>

	</v-container>
	
</template>

<script>
import Banner from './Banner.vue'
export default {
	components: {
		Banner
	},
	computed: {
		width() {
			//   console.debug('this.$vuetify.breakpoint.name', this.$vuetify.breakpoint.name)
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return 80
				case 'sm': return 100
				case 'md': return 120
				case 'lg': return 130
				case 'xl': return 150
				default: return 80
			}
		},
		getStyle() {
			return `min-height: ${this.minHeight}`
		},
		minHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "80px"
				case 'sm': return "180px"
				case 'md': return "120px"
				case 'lg': return "130px"
				case 'xl': return "150px"
				default: return "80px"
			}
		},

	},
	data() {
		return {
			items: [
				{
					id: 0, image: '/images/block-1.svg', 
					html: 'Focused on Cornwall and Devon in the UK'},
				{
					id: 1, image: '/images/block-2.svg', 
					html: 'Exploration for Copper and Tin: Critical Minerals for the Green Revolution'},
				{
					id: 2, image: '/images/block-3.svg', 
					html: 'Exploration rights over the <u>Duke of Leeds Minerals Rights Estate</u> and <u>Devon Great Consols</u>'},
				{
					id: 3, image: '/images/block-4.svg', 
					html: 'Seeking additional mineral rights in SW England to expand exploration portfolio'},
				{
					id: 4, image: '/images/block-5.svg', 
					html: 'Multi-decade expertise in tin and copper, and in the South-west of England'},
				{
					id: 5, image: '/images/block-6.svg', 
					html: 'Multiple drill-ready targets'},
			],
			alignments: [
				'start',
				'center',
				'end',
			],
		}        
	},
}
</script>

<style scoped>
.about-box {
	width: 100%;
	/* margin: 10px; */
	margin-top: 30px;
	padding: 10px;
	/* background-color: lightgray; */
	border: lightgrey 15px solid;
}
.g-accent {
	color: #ED7D31;
	/* background: none; */
}
.about-item {
	/* position: absolute; */
	margin: 0px;
	/* min-height: 180px; */
	border: #FFC000 2px solid;
	color: #4472C4;
	font-weight: bold;
	font-size: 16px;
	/* align-items: center; */
}
.about-icon {
	filter: grayscale(100%);
}
.about-row {
	/* width: 12px; */
	height: 150px;
}
.wide {
	background: #FFC000;
}
</style>>

