<template>

	<v-container fluid ref="Corporate" class="pa-0 ma-0 corporate-box">
		&nbsp;
		<h2 class="h2 text-center">PRESS / <span class="g-accent">MEDIA</span></h2>

		<!-- <v-container> -->
			<v-list>
				<v-list-item class="col-12 col-sm-6" v-for="item in items" v-bind:key="item.id">
          <v-list-item-icon>
            <v-icon size="x-large" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="item.href" target="_blank">
                <p class="subtitle h4" v-html="item.html"></p>
              </a>
            </v-list-item-title>
          </v-list-item-content>
				</v-list-item>
			</v-list>
		<!-- </v-container> -->

		<!-- <Banner text="COPPER AND TIN <u>EXPERTS</u>"></Banner> -->

	</v-container>
	
</template>

<script>
// import Banner from './Banner.vue'
export default {
	components: {
		// Banner
	},
	computed: {
		width() {
			//   console.debug('this.$vuetify.breakpoint.name', this.$vuetify.breakpoint.name)
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return 80
				case 'sm': return 100
				case 'md': return 120
				case 'lg': return 130
				case 'xl': return 150
				default: return 80
			}
		},
		getStyle() {
			return `min-height: ${this.minHeight}`
		},
		minHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "80px"
				case 'sm': return "180px"
				case 'md': return "120px"
				case 'lg': return "130px"
				case 'xl': return "150px"
				default: return "80px"
			}
		},

	},
	data() {
		return {
			items: [
				{
					id: 0,
          image: '/images/block-1.svg',
          icon: 'mdi-file',
          href: '/docs/Godolphin Exploration Wheal Vor Presentation.pdf',
					html: 'Wheal Vor Presentation'
        },
				// {
				// 	id: 1, image: '/images/block-2.svg', 
				// 	html: 'Exploration for Copper and Tin: Critical Minerals for the Green Revolution'},
				// {
				// 	id: 2, image: '/images/block-3.svg', 
				// 	html: 'Exploration rights over the <u>Duke of Leeds Minerals Rights Estate</u> and <u>Devon Great Consols</u>'},
				// {
				// 	id: 3, image: '/images/block-4.svg', 
				// 	html: 'Seeking additional mineral rights in SW England to expand exploration portfolio'},
				// {
				// 	id: 4, image: '/images/block-5.svg', 
				// 	html: 'Multi-decade expertise in tin and copper, and in the South-west of England'},
				// {
				// 	id: 5, image: '/images/block-6.svg', 
				// 	html: 'Multiple drill-ready targets'},
			],
			alignments: [
				'start',
				'center',
				'end',
			],
		}        
	},
}
</script>

<style scoped>
.g-accent {
	color: #ED7D31;
	/* background: none; */
}
.corporate-box {
	width: 100%;
	/* margin: 10px; */
	margin-top: 30px;
	padding: 10px;
	background-color: lightgray;
	border: lightgrey 15px solid;
}
.corporate-item {
	/* position: absolute; */
	margin: 0px;
	/* min-height: 180px; */
	border: #FFC000 2px solid;
	color: #4472C4;
	font-weight: bold;
	font-size: 16px;
	/* align-items: center; */
}
.corporate-icon {
	filter: grayscale(100%);
}
.corporate-row {
	/* width: 12px; */
	height: 150px;
}
.wide {
	background: #FFC000;
}
</style>

